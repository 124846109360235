export enum SIZE {
    size8 = 'var(--size-8)',
    size12 = 'var(--size-12)',
    size14 = 'var(--size-14)',
    size16 = 'var(--size-16)',
    size17 = 'var(--size-17)',
    size20 = 'var(--size-20)',
    size24 = 'var(--size-24)',
    size26 = 'var(--size-26)',
    size28 = 'var(--size-28)',
    size32 = 'var(--size-32)',
    size36 = 'var(--size-36)',
    size44 = 'var(--size-44)',
    size48 = 'var(--size-48)',
    size56 = 'var(--size-56)',
    size58 = 'var(--size-58)',
    size64 = 'var(--size-64)',
    size72 = 'var(--size-72)',
    size84 = 'var(--size-84)',
    size144 = 'var(--size-144)',
    size180 = 'var(--size-180)',
}

export enum FAMILY {
    sans = '--font-sans',
    compact = '--font-compact',
    wide = '--font-wide',
    text = '--font-text',
}

export enum WEIGHT {
    bold = 'bold',
    black = 'black',
    medium = 'medium',
    regular = 'regular',
    light = 'light',
    ultraWide = 'ultraWide',
}
